html {
    font-size: 10px;
    font-family: 'Open Sans', sans-serif; }
body {
    margin: 0;
    padding: 0;
    background-color: white; }

#wrapper {
    width: 120rem;
    margin: 0 auto;

    #header {
        height: 18rem;
        width: 100%;

        #logo {
            height: 13.5rem;
            width: 10rem;
            float: left;
            margin-top: 4.5rem; }

        h1 {
            float: left;
            margin-left: 10rem;
            margin-top: 14rem;
            line-height: 4rem;
            font-size: 3.5rem;
            font-weight: 400;
            letter-spacing: 0.2rem; }


        #navigation {
            width: 40rem;
            height: 3rem;
            float: right;
            margin-top: 15rem;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;

            a {
                font-size: 2rem;
                margin-left: 3rem;
                margin-top: 0.7rem;
                align-self: auto;
                text-decoration: none;
                color: black; }

            a:hover {
                color: #9D804A; }

            a:active {
                color: #9D804A; }

            .aActive {
                color: #9D804A; } } }

    #secSlider {
        width: 100%;
        height: 80rem;
        margin-top: 8rem;

        #divSlideShow {
            #imgSlide {
                width: 100%;
                height: 100%; } } }

    #secTriple {
        width: 100%;
        height: 42rem;
        margin-top: 8rem;

        #artDesign {
            width: 40rem;
            height: 100%;
            float: left;

            #pDesign {
                padding-right: 4rem; } }

        #artLight {
            width: 40rem;
            height: 100%;
            float: left;

            #pLight {
                padding-left: 2rem;
                padding-right: 2rem; } }

        #artWood {
            width: 40rem;
            height: 100%;
            float: left;

            #pWood {
                padding-left: 4rem; } } }

    #secEnvironment {
        height: 60rem;
        width: 100%;
        margin-top: 17rem;
        margin-bottom: 15rem;

        #artEnvLeft {
            width: 50%;
            height: 100%;
            float: left; }

        #artEnvRight {
            width: 50%;
            height: 100%;
            float: left;

            h2 {
                font-size: 3rem;
                margin-top: 5.5rem;
                margin-left: 8rem; } } }

    #secArtist {
        width: 100%;
        height: 46rem;
        margin-top: 16rem;

        #artArtistLeft {
            float: left;
            width: 74rem;

            h2 {
                font-size: 3rem;
                margin-top: 2.5rem; } }

        #artArtistRight {
            float: left;

            #artistImg {
                width: 46rem; } } }

    #secRz {
        width: 100%;
        height: 46rem;
        margin-top: 16rem;

        #artRzLeft {
            float: left;

            #rzImg {
                width: 46rem; } }

        #artRzRight {
            float: left;
            width: 74rem;

            h2 {
                font-size: 3rem;
                margin-top: 5.5rem;
                margin-left: 12rem; } } }

    #secArt {
        width: 100%;
        height: 46rem;
        margin-top: 16rem;
        margin-bottom: 15rem;

        #artArtLeft {
            float: left;
            width: 74rem;

            h2 {
              font-size: 3rem;
              margin-top: 5.5rem; } }

        #artArtRight {
            float: left;

            #artImg {
                width: 46rem; } } }

    #secTeaser {
        width: 100%;
        height: 18rem;
        margin-top: 8rem;

        h2 {
            font-size: 3rem; } }

    #secContact {
        #artContactLeft {
            width: 60rem;
            margin: 0;
            float: left;

            #ulContact {
                padding: 0;
                padding-top: 6.5rem;
                margin: 0;

                li {
                    display: flex;
                    align-items: center;

                    img {
                        width: 5rem;
                        height: 5rem; }

                    a {
                        text-decoration: none;
                        color: black; }

                    a:active {
                        color: black; }

                    a:hover {
                        color: #9D804A; } } } }

        #artContactRightEmailSend {
            background-color: #EFEFEF;
            width: 60rem;
            height: 40rem;
            float: left;
            margin-bottom: 15rem;
            box-shadow: 1rem 1rem 1rem lightgrey;
            display: none;

            #emailSuccessImg {
                position: relative;
                margin-left: 25rem;
                margin-top: 15rem;
                width: 10rem;
                height: 10rem; }

            #emailResultMsg {
                position: relative;
                text-align: center;
                font-size: 2rem;
                letter-spacing: 0.1rem; } }

        #artContactRight {
            background-color: #EFEFEF;
            width: 60rem;
            height: 40rem;
            float: left;
            margin-bottom: 15rem;
            box-shadow: 1rem 1rem 1rem lightgrey;

            .inText {
                margin: 2rem 2rem 0 2rem;
                width: 54rem;
                line-height: 4rem;
                font-weight: 100;
                letter-spacing: 0.1rem;
                font-size: 1.3rem;
                padding-left: 2rem;
                font-family: 'Open Sans', sans-serif; }

            .inText::placeholder {
                color: grey;
                font-family: 'Open Sans', sans-serif;
                font-size: 1.3rem;
                font-style: italic; }

            .inText:focus {
                box-shadow: 0 0 5px #9D804A;
                border: 1px solid #9D804A; }

            #inputText {
                line-height: 2rem;
                padding-top: 1rem; }

            #textAreaCount {
                position: relative;
                right: -50rem;
                top: -3rem;
                color: grey;
                font-size: 1.3rem; }

            #pLegend {
                margin-left: 2rem;
                margin-top: 10rem;
                font-size: 1.3rem;
                color: grey;
                font-style: italic; }

            #btnSendMsg {
                float: right;
                width: 20rem;
                margin: -0.3rem 1.8rem 2rem 0;
                background-color: #9D804A;
                border: none;
                height: 4rem;
                color: white;
                font-weight: 400;
                font-size: 1.5rem;
                letter-spacing: 0.1rem;
                box-shadow: 0.5rem 0.5rem 0.5rem lightgrey;
                border-radius: 0.5rem;
                opacity: 0.8; }

            #btnSendMsg:hover {
                opacity: 1; }

            #btnSendMsg:active {
                box-shadow: 0.1rem 0.1rem 0.1rem lightgrey;
                transform: translate(0.3rem 0.3rem); } } }

    #secImprint {
        margin-top: 8rem;

        #artImprint {
            margin-bottom: 15rem;

            h2 {
                font-size: 3rem; }

            h3 {
                font-size: 2.5rem; }

            h4 {
                font-size: 2rem; }

            p {
                font-size: 1.6rem;
                line-height: 2.3rem; } } }

    #secData {
        margin-top: 8rem;

        #artData {
            margin-bottom: 15rem;

            h2 {
                font-size: 3rem; }

            h3 {
                font-size: 2.5rem; }

            p {
                font-size: 1.6rem;
                line-height: 2.3rem; }

            ul {
                li {
                    font-size: 1.6rem;
                    line-height: 2.3rem; } } } }

    #secEvents {
        margin-top: 4rem;

        #artEvents {
            margin-bottom: 15rem;

            #noEvents {
                background-color: #efefef;
                width: 100%;
                height: 10rem;

                p {
                    text-align: center;
                    font-size: 1.6rem;
                    line-height: 2.3rem;
                    padding-top: 3.3rem; } } } } }

#footer {
    clear: both;
    width: 100%;
    height: 14rem;
    background-color: #9D804A;

    #divFooter {
        height: 14rem;
        width: 120rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        #divFootLinks {

            a {
                text-decoration: none;
                color: white;
                font-size: 2rem;
                padding-right: 4rem; } }

        #divFootSquare {
            position: absolute;

            #squareImp {
                background-color: white;
                height: 3rem;
                width: 3rem;
                transform: rotate(45deg);
                position: relative;
                left: 4rem;
                top: -7rem; }

            #squareData {
                background-color: white;
                height: 3rem;
                width: 3rem;
                transform: rotate(45deg);
                position: relative;
                left: 19rem;
                top: -7rem; } }

        #divFootSocial {
            display: flex;
            align-items: center;

            a {
                text-decoration: none;
                color: white;
                font-size: 2rem;
                padding-left: 4rem; } } } }


.divImgTriple {
    img {
        margin-left: 14rem;
        margin-top: 6rem; } }

.pTriple {
    color: black;
    font-size: 1.6rem;
    letter-spacing: 0.1rem;
    margin-top: 8rem;
    line-height: 2.3rem;
    margin-bottom: 0; }

.pEnvRight {
    margin-left: 8rem;
    color: black;
    font-size: 1.6rem;
    letter-spacing: 0.1rem;
    margin-top: 2rem;
    line-height: 2.3rem;
    margin-bottom: 1rem; }

.pArtist {
    color: black;
    font-size: 1.6rem;
    letter-spacing: 0.1rem;
    margin-top: 2rem;
    line-height: 2.3rem;
    margin-bottom: 1rem;
    padding-right: 12rem; }

.pRz {
    color: black;
    font-size: 1.6rem;
    letter-spacing: 0.1rem;
    margin-top: 2rem;
    line-height: 2.3rem;
    margin-bottom: 1rem;
    padding-left: 12rem;
    padding-right: 0rem; }

.pArt {
    color: black;
    font-size: 1.6rem;
    letter-spacing: 0.1rem;
    margin-top: 2rem;
    line-height: 2.3rem;
    margin-bottom: 1rem;
    padding-right: 12rem; }

.italic {
    font-style: italic; }

.right {
    text-align: right; }

.reference {
    margin-top: 4rem; }

.pContact {
    color: black;
    font-size: 1.6rem;
    letter-spacing: 0.1rem;
    line-height: 2.3rem;
    text-align: justify; }

.liContact {
    color: black;
    font-size: 2rem;
    letter-spacing: 0.1rem;
    line-height: 2.3rem;
    list-style: none;
    margin-bottom: 6rem; }

.aContact {
    text-decoration: none;
    margin-left: 6rem; }

.divEvents {
    display: flex;
    justify-content: space-evenly; }
